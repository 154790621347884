<template>
  <div class="subtitle">
    <p>{{ subtitle }}</p>
    <p v-if="amount !== 0">({{ amount }})</p>
  </div>
</template>

<script>
export default {
  props: {
    subtitle: {
      type: String,
      required: true,
    },
    amount: {
      type: Number,
      default: 0,
    },
  },
};
</script>
